<template>
  <div>
    <h1>Rechnungen</h1>
    <div v-if="!loading && !invoices.length">
      Noch keine Rechnungen angelegt. <br /><br />Rechnungen können pro Kunde
      mit Klick auf <v-icon class="mr-2">cloud_upload</v-icon>
      <strong>Rechnung erstellen</strong> im Menüpunkt unter
      <v-icon class="mr-2">people</v-icon>
      <router-link to="/kunden" style="text-decoration: none"
        ><strong>Kunden</strong></router-link
      >
      generiert werden.<br /><br />
      Dafür ist es notwendig, dass Sie über die
      <v-icon class="mr-2">settings</v-icon>
      <router-link to="/einstellungen" style="text-decoration: none"
        ><strong>Einstellungen</strong></router-link
      >
      Ihr Lexoffice-Konto verbinden und im Kundenbereich Ihre Kundendaten
      importieren.
    </div>
    <v-data-table
      v-else
      :footer-props="{
        'items-per-page-options': [10, 50, 100]
      }"
      :headers="headers"
      :options.sync="options"
      :server-items-length="total"
      :items="invoices"
      item-key="id"
      class="elevation-1 invoices"
      :loading="loading"
    >
      <template v-slot:item.customer_id="props">
        <span
          v-if="props.item && props.item.customer"
          :style="{ color: props.item.customer.color }"
        >
          {{ props.item.customer.name }}
        </span>
      </template>
      <template v-slot:item.created_at="props">
        {{ $date(new Date(props.item.created_at), "dd.MM.yyyy HH:mm") }}
      </template>
      <template v-slot:item.duration="props">
        <router-link
          v-if="props.item.duration"
          :to="{
            name: 'Tracks',
            query: { invoice_id: props.item.id }
          }"
          >{{ formatSeconds(props.item.duration) }} €
        </router-link>
      </template>
      <template v-slot:item.earnings="props">
        <router-link
          v-if="props.item.earnings"
          :to="{
            name: 'Tracks',
            query: { invoice_id: props.item.id }
          }"
          >{{ formatEarnings(props.item.earnings) }} €
        </router-link>
      </template>
      <template v-slot:item.actions="props">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              icon
              v-on="on"
              target="_blank"
              @click="
                openUrl(
                  'https://app.lexoffice.de/permalink/invoices/view/' +
                    props.item.lx_id
                )
              "
              ><v-icon>visibility</v-icon>
            </v-btn>
          </template>
          <span>Rechnung in Lexoffice ansehen</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn icon @click="redeem(props.item)" v-on="on"
              ><v-icon>delete</v-icon>
            </v-btn>
          </template>
          <span>Rechnung widerrufen</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </div>
</template>

<style scoped lang="scss">
.invoices {
  a {
    text-decoration: none;
  }
}
</style>

<script>
export default {
  data() {
    return {
      options: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ["created_at"],
        sortDesc: [true]
      },
      headers: [
        {
          text: "Datum",
          align: "start",
          sortable: true,
          value: "created_at"
        },
        {
          text: "Kunde",
          align: "start",
          sortable: true,
          value: "customer_id"
        },
        {
          text: "Arbeitszeit",
          align: "start",
          sortable: true,
          value: "duration"
        },
        {
          text: "Einnahmen",
          align: "end",
          sortable: true,
          value: "earnings"
        },
        {
          text: "",
          align: "end",
          sortable: true,
          value: "actions"
        }
      ],
      loading: false
    };
  },
  mounted() {
    this.load();
  },
  computed: {
    invoices() {
      return this.$store.getters["invoices/list"];
    },
    total() {
      return this.$store.getters["invoices/listTotal"] || 3;
    }
  },
  watch: {
    options: {
      handler() {
        this.load();
      },
      deep: true
    }
  },
  methods: {
    redeem(invoice) {
      this.$swal
        .fire({
          title: "Diese Rechnung von " + invoice.customer.name + " widerrufen?",
          text:
            "Alle bereits abgerechneten Zeiteinträge werden für die nächste Rechnung freigeben.",
          showCancelButton: true,
          confirmButtonText: `Rechnung widerrufen`,
          cancelButtonText: `Abbrechen`
        })
        .then(result => {
          if (result.isConfirmed) {
            this.$store
              .dispatch("invoices/update", {
                type: "invoices",
                id: invoice.id,
                redeemed: true
              })
              .then(() => {
                this.load();
              })
              .catch(() => {
                this.$store.dispatch(
                  "alerts/error",
                  "Rechnung konnte nicht widerrufen werden."
                );
              });
          }
        });
    },
    load() {
      this.loading = true;
      this.$store
        .dispatch("invoices/list", {
          page: { number: this.options.page, size: this.options.itemsPerPage },
          include: "customer,tracks",
          sort: this.options.sortBy.length
            ? (this.options.sortDesc[0] ? "-" : "") +
              this.options.sortBy.join(",")
            : null
        })
        .then(() => {
          this.loading = false;
        });
    }
  }
};
</script>
