var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',[_vm._v("Rechnungen")]),(!_vm.loading && !_vm.invoices.length)?_c('div',[_vm._v(" Noch keine Rechnungen angelegt. "),_c('br'),_c('br'),_vm._v("Rechnungen können pro Kunde mit Klick auf "),_c('v-icon',{staticClass:"mr-2"},[_vm._v("cloud_upload")]),_c('strong',[_vm._v("Rechnung erstellen")]),_vm._v(" im Menüpunkt unter "),_c('v-icon',{staticClass:"mr-2"},[_vm._v("people")]),_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":"/kunden"}},[_c('strong',[_vm._v("Kunden")])]),_vm._v(" generiert werden."),_c('br'),_c('br'),_vm._v(" Dafür ist es notwendig, dass Sie über die "),_c('v-icon',{staticClass:"mr-2"},[_vm._v("settings")]),_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":"/einstellungen"}},[_c('strong',[_vm._v("Einstellungen")])]),_vm._v(" Ihr Lexoffice-Konto verbinden und im Kundenbereich Ihre Kundendaten importieren. ")],1):_c('v-data-table',{staticClass:"elevation-1 invoices",attrs:{"footer-props":{
      'items-per-page-options': [10, 50, 100]
    },"headers":_vm.headers,"options":_vm.options,"server-items-length":_vm.total,"items":_vm.invoices,"item-key":"id","loading":_vm.loading},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.customer_id",fn:function(props){return [(props.item && props.item.customer)?_c('span',{style:({ color: props.item.customer.color })},[_vm._v(" "+_vm._s(props.item.customer.name)+" ")]):_vm._e()]}},{key:"item.created_at",fn:function(props){return [_vm._v(" "+_vm._s(_vm.$date(new Date(props.item.created_at), "dd.MM.yyyy HH:mm"))+" ")]}},{key:"item.duration",fn:function(props){return [(props.item.duration)?_c('router-link',{attrs:{"to":{
          name: 'Tracks',
          query: { invoice_id: props.item.id }
        }}},[_vm._v(_vm._s(_vm.formatSeconds(props.item.duration))+" € ")]):_vm._e()]}},{key:"item.earnings",fn:function(props){return [(props.item.earnings)?_c('router-link',{attrs:{"to":{
          name: 'Tracks',
          query: { invoice_id: props.item.id }
        }}},[_vm._v(_vm._s(_vm.formatEarnings(props.item.earnings))+" € ")]):_vm._e()]}},{key:"item.actions",fn:function(props){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","target":"_blank"},on:{"click":function($event){return _vm.openUrl(
                'https://app.lexoffice.de/permalink/invoices/view/' +
                  props.item.lx_id
              )}}},on),[_c('v-icon',[_vm._v("visibility")])],1)]}}],null,true)},[_c('span',[_vm._v("Rechnung in Lexoffice ansehen")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.redeem(props.item)}}},on),[_c('v-icon',[_vm._v("delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Rechnung widerrufen")])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }